<template>
  <div class="container">
    <div class="header"><router-link to="/user"><img src="../assets/images/back.png" alt=""></router-link> 我的书架</div>
    <div class="blian22"></div>
    <div class="books-list">
      <ul>
        <li class="flex_outside" v-for="(item,index) in booksArr" :key="index">
          <div class="left">
            <img :src="item.bookPic"/>
          </div>
          <div class="right flex_inside">
            {{item.name}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
	
	import { myBookShelf } from "@/assets/js/api";
export default {
  name: "mybooks",
  data() {
    return {
      booksArr:[{
        bookpic:require('../assets/images/booksImg.jpg'),
        name:"书名详情（二年级语文下册）"
      },{
        bookpic:require('../assets/images/booksImg.jpg'),
        name:"书名详情（二年级语文下册）"
      },{
        bookpic:require('../assets/images/booksImg.jpg'),
        name:"书名详情（二年级语文下册）"
      }]
    };
  },
  methods: {
  	getMyBookShelf(){
  		myBookShelf({}).then(res => {
					if(res.code == 0) {
						console.log(res)
					}
				});
  	}
  },
  mounted() {
  	this.getMyBookShelf()
  }
};
</script>

